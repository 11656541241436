<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="작업환경측정결과(소음제외)"
          :columns="grid.columns"
          :gridHeight="gridHeight"
          :data="measData.notNoiseList"
          :filtering="true"
          :columnSetting="false"
          :merge="grid.merge"
          :usePaging="false"
          :editable="!disabled"
          noDataLabel="작업환경측정결과를 추가하세요."
          selection="multiple"
          rowKey="workMeasurementResultId"
          @linkClick="linkClick"
        >
          <template slot="table-button">
            <q-btn-group outline>
              <c-btn v-if="isOld && !disabled && editable" label="엑셀 업로드" icon="assignment" @btnClicked="openExcelUploader"/>
              <c-btn v-if="isOld && !disabled && editable" :showLoading="false" label="LBLADD" icon="add" @btnClicked="add" />
              <c-btn v-if="isOld && !disabled && editable" :showLoading="false"  label="LBLREMOVE" icon="remove" @btnClicked="remove" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="!disabled"
                :isImmShow="true"
                :requestContentsCols="requestContentsCols"
                tableKey="workMeasurementResultId"
                ibmTaskTypeCd="ITT0000175"
                ibmTaskUnderTypeCd="ITTU000200"
                @imprChange="imprChange"
              />
            </template>
            <template v-else-if="col.name==='heaCheckup'">
              <q-btn
                v-if="Boolean(data.heaCheckupPlanId)"
                class="tableinnerBtn"
                flat round 
                :align="col.align"
                color="blue-6"
                icon="search">
                <q-popup-proxy @before-show="(e) => getCheckupResult(props)">
                  <q-banner>
                    <c-table
                      ref="checkuptable"
                      title="검진결과 목록"
                      :columns="gridCheckup.columns"
                      :gridHeight="gridCheckup.height"
                      :merge="gridCheckup.merge"
                      :data="gridCheckup.data"
                      :filtering="false"
                      :columnSetting="false"
                      :usePaging="false"
                      :isExcelDown="false"
                      :isFullScreen="false"
                      :expandAll="true"
                      @linkClick="checkupResultPopOpen"
                    >
                    </c-table>
                  </q-banner>
                </q-popup-proxy>
              </q-btn>
            </template>
            <template v-else-if="col.name==='measValue'">
              <span>
                {{props.row.measValue}}
              </span>
              <span v-if="props.row.measRecent == null || props.row.measRecent == 0" class="text-black-7">
                <br/> -
              </span>
              <span v-else-if="Math.sign(Number(props.row.measRecent)) === 1" class="text-red-7">
                <br/> {{ (props.row.measRecent) }} (증가)  
              </span>
              <span v-else-if="Math.sign(Number(props.row.measRecent)) === -1" class="text-blue-7">
                <br/> {{ (props.row.measRecent) }} (감소)
              </span>
            </template>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-measure-result-detail',
  props: {
    measData: {
      type: Object,
      default: () => ({
        workMeasurementPlanId: '',
        plantCd: '',
        heaCheckupPlanId: '',
        updateFlag: '',
        notNoiseList: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isOld: {
      type: Boolean,
      default: false
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        // merge: [
        //   { index: 0, colName: 'processDeptMergeGroup' },
        //   { index: 1, colName: 'processDeptMergeGroup' },
        // ],
        columns: [],
        data: [],
        height: '520px'
      },
      gridCheckup: {
        merge: [
          { index: 0, colName: 'mergeGroup' },
          { index: 1, colName: 'mergeGroup' },
          { index: 2, colName: 'mergeGroup' },
        ],
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자명',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
            type: 'link'
          },
          {
            name: 'checkupDate',
            field: 'checkupDate',
            label: '검진일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'judgmentName',
            field: 'judgmentName',
            label: '판정',
            align: 'center',
            style: 'width: 50px',
            sortable: false,
          },
          {
            name: 'diseaseName',
            field: 'diseaseName',
            label: '질환',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'hazardNames',
            field: 'hazardNames',
            label: '관련유해인자',
            align: 'left',
            style: 'width: 450px',
            sortable: false,
          },
        ],
        data: [],
        height: '400px',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      searchParam: {
        processCd: '',
        processName: '',
        deptCd: '',
        workMeasurementPlanId: '',
        hazardClassFstCd: null,
        startYmd: '',
        endYmd: '',
      },
      measTime: [],
      date: [],
      editable: false,
      listCheckupUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  computed: {
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['processCd', 'workPlace', 'hazardName', 'workerName'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
    gridHeight() {
      let height = this.contentHeight - 300;
      if (height < 400) {
        height = 400;
      }
      return height + 'px';
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.workmeas.result.list.url;
      this.insertUrl = transactionConfig.sai.workmeas.plan.insert.url;
      this.updateUrl = transactionConfig.sai.workmeas.plan.update.url;
      this.deleteUrl = transactionConfig.sai.workmeas.plan.delete.url;
      this.completeUrl = transactionConfig.sai.workmeas.plan.complete.url;
      this.deleteResultUrl = transactionConfig.sai.workmeas.result.deletes.url;
      this.listCheckupUrl = selectConfig.hea.checkup.result.list.url;
      this.setHeader();
    },
    setHeader() {
      this.grid.columns = [
        {
          fix: true,
          name: 'processCd',
          field: 'processCd',
          label: '공정명',
          align: 'center',
          style: 'width:230px',
          sortable: false,
        },
        {
          fix: true,
          name: 'workPlace',
          field: 'workPlace',
          label: '단위작업장소',
          align: 'left',
          style: 'width:150px',
          sortable: false,
        },
        {
          fix: true,
          name: 'hazardName',
          field: 'hazardName',
          label: '유해인자',
          align: 'center',
          style: 'width:180px',
          type: 'link',
          sortable: false,
        },
        // {
        //   name: 'heaCheckup',
        //   field: 'heaCheckup',
        //   label: '특수검진<br/>대상자',
        //   align: 'center',
        //   style: 'width:100px',
        //   type: 'custom',
        //   sortable: false
        // },
        {
          fix: true,
          name: 'casNo',
          field: 'casNo',
          label: 'CAS NO.',
          style: 'width:80px',
          align: 'center',
          sortable: false,
        },
        {
          name: 'measDate',
          field: 'measDate',
          label: '측정일자',
          style: 'width:100px',
          align: 'center',
          sortable: true,
        },
        {
          name: 'workCount',
          field: 'workCount',
          label: '근로자수',
          align: 'center',
          sortable: true,
        },
        {
          name: 'shiftType',
          field: 'shiftType',
          label: '근로형태',
          style: 'width:140px',
          align: 'center',
          sortable: true,
        },
        {
          name: 'occurTime',
          field: 'occurTime',
          label: '발생시간',
          align: 'right',
          sortable: true,
        },
        {
          name: 'measPositionName',
          field: 'measPositionName',
          label: '측정위치',
          align: 'left',
          sortable: true,
        },
        {
          name: 'workerName',
          field: 'workerName',
          label: '근로자명',
          align: 'center',
          sortable: true,
        },
        {
          name: 'measTime',
          field: 'measTime',
          label: '시작~종료시간',
          align: 'center',
          sortable: true,
        },
        {
          name: 'measCount',
          field: 'measCount',
          label: '측정횟수',
          align: 'center',
          sortable: true,
        },
        {
          name: 'measValueEtc',
          field: 'measValueEtc',
          label: '측정치기타',
          align: 'center',
          sortable: true,
        },
        {
          name: 'measValue',
          field: 'measValue',
          label: '측정치',
          align: 'center',
          style: 'width:100px',
          type: 'custom',
          sortable: true,
        },
        {
          name: 'twaPrevEtc',
          field: 'twaPrevEtc',
          label: '전회기타',
          align: 'center',
          sortable: true,
        },
        {
          name: 'twaPrev',
          field: 'twaPrev',
          label: '전회',
          align: 'center',
          sortable: true,
        },
        {
          name: 'twaCurrEtc',
          field: 'twaCurrEtc',
          label: '금회기타',
          align: 'center',
          sortable: true,
        },
        {
          name: 'twaCurr',
          field: 'twaCurr',
          label: '금회',
          align: 'center',
          sortable: true,
        },
        {
          name: 'afterExposureStandard',
          field: 'afterExposureStandard',
          label: '보정후<br>노출기준',
          align: 'center',
          sortable: true,
        },
        {
          name: 'exposureDivision',
          field: 'exposureDivision',
          label: '노출구분',
          align: 'center',
          sortable: true,
        },
        {
          name: 'exposureUnit',
          field: 'exposureUnit',
          label: '노출단위',
          align: 'center',
          sortable: true,
        },
        {
          name: 'exposureResult',
          field: 'exposureResult',
          label: '결과',
          align: 'center',
          sortable: true,
        },
        {
          name: 'customCol',
          field: 'customCol',
          label: 'LBLIMPRNO_PROGRESS',
          align: 'center',
          style: 'width:150px',
          type: 'custom',
          sortable: false
        },
      ]
    },
    linkClick(row) {
      this.add(row);
    },
    add(row) {
      this.popupOptions.title = '작업환경측정결과(소음제외) 상세'; // 작업환경측정결과 상세
      this.popupOptions.target = () => import(`${'@/pages/sai/wmr/workMeasureNotNoiseDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.param = {
        disabled: this.disabled,
        workMeasurementPlanId: this.measData.workMeasurementPlanId,
        workMeasurementResultId: row ? row.workMeasurementResultId : '',
        plantCd: this.measData.plantCd,
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeResultPopup;
    },
    closeResultPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit('getDetail')
    },
    getCheckupResult(props) {
      this.$http.url = this.$format(this.listCheckupUrl, this.measData.heaCheckupPlanId);
      this.$http.type = 'GET';
      this.$http.param = {
        deptCd: props.row.deptCd,
        hazardCd: props.row.hazardCd,
      };
      this.$http.request((_result) => {
        this.gridCheckup.data = _result.data;
      },);
    },
    checkupResultPopOpen(result) {
      this.popupOptions.target = () => import(`${"@/pages/hea/checkUpResultDetail.vue"}`);
      this.popupOptions.title = result.userName + '의 건강검진 결과 상세';
      this.popupOptions.isFull = true;
      this.popupOptions.param = {
        heaCheckupResultId: result.heaCheckupResultId,
        checkupTypeCd: result.checkupTypeCd,
        checkupDate: result.checkupDate,
      };
      this.popupOptions.visible = true;
      this.popupOptions.width = '90%';
      this.popupOptions.closeCallback = this.closeCheckupResultPopup;
    },
    closeCheckupResultPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '측정결과를 삭제하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            let deldata = [];
            this.$_.forEach(selectData, item => {
              deldata.push(item);
            })
            this.$http.url = this.deleteResultUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.measData.notNoiseList = this.$_.reject(this.measData.notNoiseList, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
              this.$emit('getDetail')
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    openExcelUploader() {
      this.popupOptions.title = '작업환경측정결과 업로드'; // 즉시개선
      this.popupOptions.param = {
        plantCd: this.measData.plantCd,
        noiseFlag: 'N'
      }
      this.popupOptions.target = () => import(`${'./workMeasureNotNoiseExcelUpload.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          item.workMeasurementPlanId = this.measData.workMeasurementPlanId
          item.workMeasurementResultId = uid();  // 일련번호
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = '';  // 수정자 ID
          item.noiseFlag = 'N';
          item.editFlag = 'C';
        })
        this.$http.url = transactionConfig.sai.workmeas.excel.results.url;
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.$emit('getDetail')
        },);
      }
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      this.$emit('getDetail')
    },
  }
};
</script>
